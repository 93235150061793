import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";


const stregsubrequirement = Loadable({
  loader: () => import("./components/stregsubrequirement"),
  loading: Loading
});



export const routes = [
  
  {
    path: "/contract-requirement/stregsubrequirement",
    exact: true,
    component: stregsubrequirement,
    name: "Statutory/Regulatory Submission Requirements"
  }
  
  

];
