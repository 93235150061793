import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";


const Vendor = Loadable({
  loader: () => import("./components/vendor"),
  loading: Loading
});


const Organization = Loadable({
  loader: () => import("./components/organization"),
  loading: Loading
});

const Designation = Loadable({
  loader: () => import("./components/designation"),
  loading: Loading
});

const Doctype = Loadable({
  loader: () => import("./components/doctype"),
  loading: Loading
});
const Documentstatus = Loadable({
  loader: () => import("./components/docstatus"),
  loading: Loading
});

const documenttype = Loadable({
  loader: () => import("./components/documenttype"),
  loading: Loading
});

const agency = Loadable({
  loader: () => import("./components/agency"),
  loading: Loading
});

const budget = Loadable({
  loader: () => import("./components/budget"),
  loading: Loading
});

const target_plan = Loadable({ 
  loader: () => import("./components/target_plan"),
  loading: Loading
});
const t7 = Loadable({ 
  loader: () => import("./components/t7"),
  loading: Loading
});
const access_code = Loadable({ 
  loader: () => import("./components/access_code"),
  loading: Loading
});
const targetplan_accesscode_linking = Loadable({ 
  loader: () => import("./components/targetplan_accesscode_linking"),
  loading: Loading
});
const office_location = Loadable({ 
  loader: () => import("./components/office_location"),
  loading: Loading
});

const bank = Loadable({ 
  loader: () => import("./components/bank"),
  loading: Loading
});

const area = Loadable({ 
  loader: () => import("./components/area"),
  loading: Loading
});
const reforecast_plan = Loadable({ 
  loader: () => import("./components/reforecast_plan"),
  loading: Loading
});


export const routes = [
  
  
  {
    path: "/masters/organization",
    exact: true,
    component: Organization,
    name: "Organization Master"
  },
  {
    path: "/masters/designation",
    exact: true,
    component: Designation,
    name: "Designation Master"
  },
  {
    path: "/masters/doctype",
    exact: true,
    component: Doctype,
    name: "Document Type Master"
  },
  {
    path: "/masters/docstatus",
    exact: true,
    component: Documentstatus,
    name: "Document Status Master"
  },
  {
    path: "/masters/documenttype",
    exact: true,
    component: documenttype,
    name: "Document Type"
  },
  {
    path: "/masters/vendor",
    exact: true,
    component: Vendor,
    name: "Vendor Master"
  },
  {
    path: "/masters/agency",
    exact: true,
    component: agency,
    name: "Agency Master"
  },
  {
    path: "/masters/budget",
    exact: true,
    component: budget,
    name: "Budget Master"
  },
  {
    path: "/masters/target_plan",
    exact: true,
    component: target_plan,
    name: "Target Plan Master"
  },
  {
    path: "/masters/t7",
    exact: true,
    component: t7,
    name: "T7"
  },
  {
    path: "/masters/access_code",
    exact: true,
    component: access_code,
    name: "Access Code"
  },
  {
    path: "/masters/targetplan_accesscode_linking",
    exact: true,
    component: targetplan_accesscode_linking,
    name: "Target Plan Linking"
  },
  {
    path: "/masters/office_location",
    exact: true,
    component: office_location,
    name: "Office Location"
  },
  {
  path: "/masters/bank",
  exact: true,
  component: bank,
  name: "Bank"
},
{
  path: "/masters/area",
  exact: true,
  component: area,
  name: "Area"
},
{
  path: "/masters/reforecast_plan",
  exact: true,
  component: reforecast_plan,
  name: "Reforecast Plan"
},
  
  
  
];
