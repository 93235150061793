import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const Usermanagement = Loadable({
  loader: () => import("./components/Usermanagement"),
  loading: Loading
});

const Setting = Loadable({
  loader: () => import("./components/Setting"),
  loading: Loading
});

const Explorer = Loadable({
  loader: () => import("./components/Explorer"),
  loading: Loading
});



const approvals = Loadable({
  loader: () => import("./components/approvals"),
  loading: Loading
});

const Userpermission = Loadable({
  loader: () => import("./components/userpermission"),
  loading: Loading
});



const deviceregistration = Loadable({
  loader: () => import("./components/deviceregistration"),
  loading: Loading
});


const accesscodevillagelink = Loadable({
  loader: () => import("./components/accesscodevillagelink"),
  loading: Loading
});
const userotp = Loadable({
  loader: () => import("./components/userotp"),
  loading: Loading
});



export const routes = [
  {
    path: "/administration/manageusers",
    exact: true,
    component: Usermanagement,
    name: "User Management"
  },
  {
    path: "/administration/userpermission",
    exact: true,
    component: Userpermission,
    name: "User Permission"
  },
  {
    path: "/administration/settings",
    exact: true,
    component: Setting,
    name: "Setting"
  },
  {
    path: "/administration/explorer",
    exact: true,
    component: Explorer,
    name: "Explorer"
  },
  
  {
    path: "/administration/approvals",
    exact: true,
    component: approvals,
    name: "Approvals"
  },
  {
    path: "/administration/deviceregistration",
    exact: true,
    component: deviceregistration,
    name: "deviceregistration"
  },
  {
    path: "/administration/accesscodevillagelink",
    exact: true,
    component: accesscodevillagelink,
    name: "Access Code Village linking"
  },
  {
    path: "/administration/userotp",
    exact: true,
    component: userotp,
    name: "User OTP"
  }
  
  
  
  
];
