import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";


const landacquisitionid = Loadable({
  loader: () => import("./components/landacquisitionid"),
  loading: Loading
});

const lasiteassestsaffected = Loadable({
  loader: () => import("./components/lasiteassestsaffected"),
  loading: Loading
});

const lasitegeneralinfo = Loadable({
  loader: () => import("./components/lasitegeneralinfo"),
  loading: Loading
});

const lasitefamilyparticulars = Loadable({
  loader: () => import("./components/lasitefamilyparticulars"),
  loading: Loading
});

const lasiteoverallimpact = Loadable({
  loader: () => import("./components/lasiteoverallimpact"),
  loading: Loading
});

const lasiteprojectinfo = Loadable({
  loader: () => import("./components/lasiteprojectinfo"),
  loading: Loading
});

const lasiteresetrehab = Loadable({
  loader: () => import("./components/lasiteresetrehab"),
  loading: Loading
});

const lasiteaffectedbusiness = Loadable({
  loader: () => import("./components/lasiteaffectedbusiness"),
  loading: Loading
});

export const routes = [
  
  {
    path: "/survey/landacquisitionid",
    exact: true,
    component: landacquisitionid,
    name: "Identification"
  },
  {
    path: "/survey/lasiteassestsaffected",
    exact: true,
    component: lasiteassestsaffected,
    name: "List of assets affected"
  },
  {
    path: "/survey/lasitegeneralinfo",
    exact: true,
    component: lasitegeneralinfo,
    name: "General Information"
  },
  {
    path: "/survey/lasitefamilyparticulars",
    exact: true,
    component: lasitefamilyparticulars,
    name: "Family Particulars"
  },
  {
    path: "/survey/lasiteoverallimpact",
    exact: true,
    component: lasiteoverallimpact,
    name: "Overall Impact"
  },
  {
    path: "/survey/lasiteaffectedbusiness",
    exact: true,
    component: lasiteaffectedbusiness,
    name: "Overall Impact"
  },
  {
    path: "/survey/lasiteprojectinfo",
    exact: true,
    component: lasiteprojectinfo,
    name: "Project Related Info"
  },
  {
    path: "/survey/lasiteresetrehab",
    exact: true,
    component: lasiteresetrehab,
    name: "Resettlement and Rehabilitation"
  }
  

];
