import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const Dashboard = Loadable({
  loader: () => import("./components/Dashboard"),
  loading: Loading
});

const Dashboard2 = Loadable({
  loader: () => import("./components/Dashboard2"),
  loading: Loading
});

const Social = Loadable({
  loader: () => import("./components/Social"),
  loading: Loading
});


const Gmap = Loadable({
  loader: () => import("./components/Gmap"),
  loading: Loading
});

const Dashboarddms = Loadable({
  loader: () => import("./components/Dashboarddms"),
  loading: Loading
});

const Dashboardsummary = Loadable({
  loader: () => import("./components/Dashboardsummary"),
  loading: Loading
});


const Gallery = Loadable({
  loader: () => import("./components/Gallery"),
  loading: Loading
});

const cde = Loadable({
  loader: () => import("./components/cde"),
  loading: Loading
});

const dde = Loadable({
  loader: () => import("./components/Dashboarddataentry"),
  loading: Loading
});

export const routes = [
  {
    path:  "/",
    exact: true,
    name: "Dashboard",
    component: Dashboard
  },
  {
    path:  "/dashboard/analytics",
    exact: true,
    name: "Dashboard",
    component: Dashboard
  },
  {
    path:  "/dashboard/analytics2",
    exact: true,
    name: "Dashboard2",
    component: Dashboard2
  },
  {
    path: "/dashboard/social-wall",
    exact: true,
    name: "Social",
    component: Social
  },
  {
    path: "/dashboard/map",
    exact: true,
    name: "Map",
    component: Gmap
  },
  {
    path: "/dashboard/dms",
    exact: true,
    name: "DMS",
    component: Dashboarddms
  },
  {
    path: "/dashboard/dashboardsummary",
    exact: true,
    name: "DMS",
    component: Dashboardsummary
  },
  {
    path: "/dashboard/gallery",
    exact: true,
    component: Gallery,
    name: "Gallery"
  },
  {
    path: "/dashboard/cde",
    exact: true,
    component: cde,
    name: "Pacakge Summary"
  }
  ,
  {
    path: "/dashboard/dde",
    exact: true,
    component: dde,
    name: "Upload Report"
  }
  

  
  
];
