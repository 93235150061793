import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";


const stregsubmission = Loadable({
  loader: () => import("./components/stregsubmission"),
  loading: Loading
});





const transmittal = Loadable({
  loader: () => import("./components/transmittal"),
  loading: Loading
});


const documentsubmittalschedule = Loadable({
  loader: () => import("./components/documentsubmittalschedule"),
  loading: Loading
});


const technicalqueryfwdres = Loadable({
  loader: () => import("./components/technicalqueryfwdres"),
  loading: Loading
});

const itp = Loadable({
  loader: () => import("./components/itp"),
  loading: Loading
});

const changerequest = Loadable({
  loader: () => import("./components/changerequest"),
  loading: Loading
});

const engineerinstruction = Loadable({
  loader: () => import("./components/engineerinstruction"),
  loading: Loading
});

const eot = Loadable({
  loader: () => import("./components/eot"),
  loading: Loading
});

const contractorrequest = Loadable({
  loader: () => import("./components/contractorrequest"),
  loading: Loading
});

const crfwdres = Loadable({
  loader: () => import("./components/crfwdres"),
  loading: Loading
});

const progresssummary = Loadable({
  loader: () => import("./components/progresssummary"),
  loading: Loading
});

const ipc = Loadable({
  loader: () => import("./components/ipc"),
  loading: Loading
});

const ipa = Loadable({
  loader: () => import("./components/ipa"),
  loading: Loading
});

const kes = Loadable({
  loader: () => import("./components/keyelementstatus"),
  loading: Loading
});


const viewboq = Loadable({
  loader: () => import("./components/viewboq"),
  loading: Loading
});


const viewipa = Loadable({
  loader: () => import("./components/viewipa"),
  loading: Loading
});



const deductions = Loadable({
  loader: () => import("./components/deductions"),
  loading: Loading
});





export const routes = [
  
  {
    path: "/contract-data-entry/stregsubmission",
    exact: true,
    component: stregsubmission,
    name: "Statutory/Regulatory Submission"
  },

  {
    path: "/contract-data-entry/transmittal",
    exact: true,
    component: transmittal,
    name: "Transmittal"
  },
  {
    path: "/contract-data-entry/documentsubmittalschedule",
    exact: true,
    component: documentsubmittalschedule,
    name: "Document Submittal Schedule"
  },
  {
    path: "/contract-data-entry/technicalqueryfwdres",
    exact: true,
    component: technicalqueryfwdres,
    name: "Technical Query forwarding"
  },
  {
    path: "/contract-data-entry/itp",
    exact: true,
    component: itp,
    name: "ITP"
  },
  {
    path: "/contract-data-entry/changerequest",
    exact: true,
    component: changerequest,
    name: "Change Request"
  },
  
  {
    path: "/contract-data-entry/engineerinstruction",
    exact: true,
    component: engineerinstruction,
    name: "Engineer Instruction"
  },
  {
    path: "/contract-data-entry/eot",
    exact: true,
    component: eot,
    name: "EOT"
  },
  {
    path: "/contract-data-entry/contractorrequest",
    exact: true,
    component: contractorrequest,
    name: "Contractor Request"
  },
  {
    path: "/contract-data-entry/crfwdres",
    exact: true,
    component: crfwdres,
    name: "Contractor Request forwarding"
  },
  {
    path: "/contract-data-entry/progresssummary",
    exact: true,
    component: progresssummary,
    name: "Progress Summary"
  },
  {
    path: "/contract-data-entry/ipc",
    exact: true,
    component: ipc,
    name: "IPC"
  },
  {
    path: "/contract-data-entry/ipa",
    exact: true,
    component: ipa,
    name: "IPA"
  },
  {
    path: "/contract-data-entry/keyelementstatus",
    exact: true,
    component: kes,
    name: "Key Element Status"
  },
  {
    path: "/contract-data-entry/viewboq",
    exact: true,
    component: viewboq,
    name: "View BOQ"
  },
  {
    path: "/contract-data-entry/viewipa",
    exact: true,
    component: viewipa,
    name: "View IPA"
  },
  {
    path: "/contract-data-entry/deductions",
    exact: true,
    component: deductions,
    name: "Deductions"
  }
  
  
  
  
  

];
