import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";


const correspondence = Loadable({
  loader: () => import("./components/correspondence"),
  loading: Loading
});


export const routes = [
  {
    path: "/correspondence/correspondence",
    exact: true,
    component: correspondence,
    name: "Correspondence"
  }

  

];
