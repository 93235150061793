import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const search = Loadable({
  loader: () => import("./components/search"),
  loading: Loading
});

const timeline = Loadable({
  loader: () => import("./components/Timeline"),
  loading: Loading
});


const docexplorer = Loadable({
  loader: () => import("./components/docexplorer"),
  loading: Loading
});

const redirecttodms = Loadable({
  loader: () => import("./components/redirecttodms"),
  loading: Loading
});


export const routes = [
  
  {
    path: "/dms/search",
    exact: true,
    component: search,
    name: "Document Search"
  },
  {
    path: "/dms/timeline",
    exact: true,
    component: timeline,
    name: "Document Timeline"
  },
  {
    path: "/dms/explorer",
    exact: true,
    component: docexplorer,
    name: "Document Explorer"
  },
  {
    path: "/dms/dms",
    exact: true,
    component: redirecttodms,
    name: "Document Search"
  }
  
  
  
];
